export default {
  "attachment-sidebar": "qcM",
  "sidebar-scroll": "qcP",
  "top-section": "qcl",
  "timeline-section": "qcX",
  "sidebar-header": "qcC",
  "matched-section": "qck",
  "collapsible-timeline": "qcJ",
  "timeline-status-label": "qcs",
  "m-24": "qcF"
};
