export default {
  "row": "qEJ",
  "icon": "qEs",
  "cell": "qEF",
  "cell-date": "qEg",
  "cell-amount": "qED",
  "cell-status": "qEV",
  "cell-content": "qEL",
  "align-right": "qET"
};
