export default {
  "bank-account-option": "qEq",
  "row": "qEZ",
  "active": "qER",
  "animated": "qEQ",
  "fadein-item": "qEe",
  "cell": "qEB body-2",
  "cell-content": "qEE",
  "subtitle": "qEd caption",
  "no-padding": "qEI",
  "empty": "qEt",
  "note": "qEM",
  "cell-content-amount": "qEP body-1",
  "cell-content-receipt": "qEl body-1",
  "cell-quick-actions": "qEX",
  "account-selector": "qEC",
  "cell-type": "qEk"
};
