export default {
  "row": "cup",
  "disabled": "cux",
  "cell": "cuw body-2",
  "cell-link": "cuN",
  "cell-content": "cuv",
  "item-truncate-text": "cuo",
  "item-name-cell": "cun",
  "item-account-cell": "cui",
  "icon": "cuA",
  "icon-download": "cuY"
};
