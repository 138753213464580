export default {
  "item": "qRq",
  "checkbox": "qRZ",
  "details": "qRR",
  "flex": "qRQ",
  "header": "qRe qRQ",
  "flex-no-gap": "qRB qRQ",
  "file-name": "qRE",
  "label": "qRd body-2",
  "self-icon": "qRI",
  "einvoice-icon": "qRt",
  "type-value": "qRM",
  "with-separator": "qRP",
  "dimmed": "qRl"
};
