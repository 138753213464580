export default {
  "wrapper": "cjq",
  "content": "cjZ",
  "title": "cjR",
  "aside": "cjQ",
  "description": "cje",
  "faq-link": "cjB",
  "close-btn": "cjE",
  "container": "cjd",
  "mandate-field-list": "cjI",
  "mandate-field-item": "cjt",
  "mandate-field-item__dot": "cjM",
  "mandate-field-item__text": "cjP"
};
