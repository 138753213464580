export default {
  "wrapper": "qdE",
  "section-wrapper": "qdd",
  "header": "qdI",
  "title": "qdt body-1",
  "close-icon": "qdM",
  "section": "qdP",
  "section-title": "qdl caption-bold",
  "labels": "qdX",
  "labels-title": "qdC",
  "section-checkbox": "qdk",
  "grow": "qdJ scroll",
  "label": "qds",
  "buttons": "qdF"
};
