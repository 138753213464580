export default {
  "savings-account": "cyf",
  "closed": "cyK",
  "badge": "cyG",
  "subtitle": "cyr",
  "amount": "cyb",
  "processing": "cyy",
  "gain": "cyU",
  "active": "cyj",
  "progress": "cyW",
  "progress-bar": "cyu",
  "progress-text": "cUS",
  "info": "cUc",
  "menu-item": "cUq body-2",
  "close-account": "cUZ"
};
