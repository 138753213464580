export default {
  "progress-container": "qEY",
  "line-container": "qEh",
  "dashed-line": "qEf",
  "solid-line": "qEK",
  "circle-container": "qEG",
  "circle": "qEr",
  "current-step-dot": "qEb",
  "success-icon": "qEy",
  "rejected-icon": "qEU"
};
