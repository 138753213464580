export default {
  "card": "qFy",
  "card-primary": "qFU",
  "content": "qFj",
  "caption-text": "qFW",
  "content-primary": "qFu",
  "disabled": "qgS",
  "illustration": "qgc",
  "illustration-primary": "qgq",
  "illustration-disabled": "qgZ",
  "cta": "qgR",
  "dismiss-button": "qgQ",
  "buttons": "qge",
  "required-badge": "qgB"
};
