export default {
  "header-cell": "qkp",
  "empty": "qkx",
  "header-type": "qkw",
  "header-requester": "qkN",
  "header-amount": "qkv",
  "header-approval": "qko",
  "header-content": "qkn caption-bold",
  "active": "qki",
  "row-compact": "qkA",
  "row-sidebar": "qkY",
  "hidden": "qkh",
  "animated": "qkf",
  "fadein-item": "qkK",
  "animated-cell": "qkG"
};
