export default {
  "row": "qJY body-1",
  "active": "qJh",
  "cell": "qJf",
  "empty": "qJK",
  "ellipsis": "qJG",
  "text-secondary": "qJr",
  "cell-type": "qJb",
  "cell-reason": "qJy",
  "cell-amount": "qJU",
  "row-sidebar": "qJj",
  "animated": "qJW",
  "fadein-item": "qJu",
  "animated-cell": "qsS"
};
