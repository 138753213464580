export default {
  "sidepanel": "qQN",
  "close-icon": "qQv",
  "close-button": "qQo",
  "supplier-card": "qQn",
  "supplier-card-details": "qQi",
  "supplier-name": "qQA title-3 mb-8",
  "supplier-card-placeholder": "qQY",
  "box": "qQh",
  "box-element": "qQf",
  "related-invoices-title": "qQK",
  "placeholder-container": "qQG",
  "related-invoices-wrapper": "qQr",
  "related-invoice-placeholder": "qQb",
  "related-invoice-placeholder-inner": "qQy",
  "actions": "qQU",
  "currency": "qQj"
};
