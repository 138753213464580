export default {
  "header-cell": "qle",
  "header-content": "qlB caption-bold",
  "active": "qlE",
  "align-right": "qld",
  "empty": "qlI",
  "align-checkbox": "qlt",
  "col-5": "qlM",
  "col-9": "qlP",
  "emitted-at-header-tag": "qll"
};
