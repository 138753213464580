export default {
  "custom-export-loading": "qIk",
  "title": "qIJ",
  "description": "qIs",
  "section": "qIF",
  "placeholder-avatar": "qIg",
  "_with-avatar": "qID",
  "_with-list": "qIV",
  "list-item": "qIL",
  "radio": "qIT",
  "header": "qIz",
  "header-line": "qIa"
};
