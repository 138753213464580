export default {
  "insight-pill": "qZB",
  "interactive": "qZE",
  "focus": "qZd",
  "subtitle": "qZI caption",
  "hidden": "qZt",
  "missing-amounts-warning": "qZM",
  "totals": "qZP",
  "counter": "qZl title-3",
  "loading": "qZX",
  "error": "qZC",
  "title": "qZk title-4"
};
