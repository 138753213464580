export default {
  "cell": "qQk",
  "cell-label-container": "qQJ",
  "cell-label": "qQs",
  "overflow": "qQF",
  "missing": "qQg",
  "credit-note-amount": "qQD",
  "payable-amount": "qQV",
  "amount": "qQL"
};
