export default {
  "transfer-form": "qCT",
  "transfer-form-title": "qCz",
  "transfer-form-subtitle": "qCa",
  "form": "qCH",
  "form-footer": "qCO",
  "footer-btn": "qCm",
  "header": "qCp",
  "main-settings": "qCx",
  "additional-settings": "qCw",
  "form-container": "qCN"
};
