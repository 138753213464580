/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';

import CURRENCIES from 'qonto/constants/currencies';
// @ts-expect-error
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import { Avatar } from 'qonto/react/components/suppliers/table/item/avatar';

interface SuppliersTableItemSignature {
  // The arguments accepted by the component
  Args: {
    isSelected?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class SuppliersTableItemComponent extends Component<SuppliersTableItemSignature> {
  avatar = Avatar;

  get defaultCurrency() {
    return CURRENCIES.default;
  }

  get isUnarchived() {
    // @ts-expect-error
    return this.args.status === SUPPLIER_STATUSES.UNARCHIVED;
  }

  get supplierFirstLetter() {
    // @ts-expect-error
    return this.args.supplier?.name?.match(/\w/)?.[0];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Suppliers::Table::Item': typeof SuppliersTableItemComponent;
  }
}
