export default {
  "fileupload": "qFv",
  "file": "qFo",
  "label": "qFn",
  "zone": "qFi",
  "error": "qFA",
  "hide": "qFY",
  "dropzone": "qFh",
  "hidden": "qFf",
  "upload-icon": "qFK",
  "dropping": "qFG",
  "bounce2": "qFr",
  "error-message": "qFb"
};
