export default {
  "invoice-sidebar": "cWR",
  "body": "cWQ",
  "box": "cWe",
  "box-header": "cWB",
  "sidebar-box": "cWE",
  "border-top": "cWd",
  "row": "cWI",
  "greyed-out": "cWt",
  "strike-through": "cWM"
};
