export default {
  "modal": "qdA",
  "section": "qdY",
  "header": "qdh",
  "title": "qdf",
  "icon": "qdK",
  "content": "qdG",
  "export-template-wrapper": "qdr",
  "export-template-radio": "qdb",
  "export-template-content": "qdy",
  "export-template-label": "qdU",
  "export-template-button": "qdj",
  "export-template-list": "qdW",
  "inactive": "qdu",
  "export-template-title": "qIS",
  "export-template-description": "qIc",
  "export-template-item-content": "qIq",
  "permission-disclaimer-container": "qIZ",
  "export-template-footer": "qIR",
  "export-template-cta-container": "qIQ",
  "upsell": "qIe",
  "upsell-section": "qIB",
  "_content": "qIE",
  "_cta": "qId",
  "upsell-title": "qII",
  "upsell-description": "qIt"
};
