export default {
  "form": "qqt",
  "form-title": "qqM title2",
  "row": "qqP",
  "col": "qql",
  "actions": "qqX",
  "buttons": "qqC",
  "collapsible-timeline": "qqk",
  "link": "qqJ",
  "timeline-status-label": "qqs",
  "section": "qqF",
  "einvoicing-disclaimer": "qqg",
  "disclaimer-section": "qqD qqF",
  "related-documents-card-margin-top": "qqV",
  "my-24": "qqL"
};
