export default {
  "modal": "qdV",
  "section": "qdL",
  "header": "qdT",
  "title": "qdz",
  "separator-title": "qda",
  "icon": "qdH",
  "formats": "qdO",
  "columns": "qdm",
  "column-list": "qdp",
  "draggable-column": "qdx",
  "column-field": "qdw",
  "column-selector": "qdN",
  "column-with-error": "qdv",
  "handle": "qdo",
  "label-color": "qdn",
  "disclaimer": "qdi"
};
