export default {
  "row": "qkr body-1",
  "active": "qkb",
  "cell": "qky",
  "empty": "qkU",
  "ellipsis": "qkj",
  "text-secondary": "qkW",
  "transfer-type": "qku",
  "transfer-type__icon": "qJS",
  "quick-actions": "qJc",
  "amount": "qJq",
  "cell-approve": "qJZ",
  "cell-type": "qJR",
  "cell-requester": "qJQ",
  "account-select": "qJe",
  "row-compact": "qJB",
  "row-sidebar": "qJE",
  "cell-amount": "qJd",
  "hidden": "qJI",
  "animated": "qJt",
  "fadein-item": "qJM",
  "animated-cell": "qJP"
};
