export default {
  "wrapper": "qXt",
  "title": "qXM",
  "activity-tag": "qXP",
  "select": "qXl",
  "tooltip": "qXX",
  "date-picker": "qXC",
  "internal-notes": "qXk",
  "scheduled": "qXJ",
  "payroll-selector-wrapper": "qXs"
};
