export default {
  "sidebar-header": "qMu",
  "sidebar-header__top": "qPS",
  "sidebar-header__body": "qPc",
  "sidebar-header__content": "qPq",
  "sidebar-header__date": "qPZ",
  "infobox": "qPR",
  "infobox--link": "qPQ",
  "ghost": "qPe",
  "fail": "qPB",
  "r-transaction-disclaimer": "qPE",
  "hold-disclaimer": "qPd",
  "transaction-link": "qPI body-2",
  "icon-link": "qPt"
};
