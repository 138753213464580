export default {
  "modal": "cjk",
  "container": "cjJ",
  "form-container": "cjs",
  "preview-container": "cjF",
  "preview-content": "cjg",
  "switch-container": "cjD",
  "supplier-invoice-preview": "cjV",
  "header": "cjL",
  "footer": "cjT",
  "form-content": "cjz",
  "form-content-section": "cja",
  "form-disclaimer": "cjH",
  "hidden": "cjO"
};
