export default {
  "button-wrapper": "qtl",
  "btn-filter": "qtX",
  "name-wrapper": "qtC",
  "preset-name": "qtk",
  "cancel-placeholder": "qtJ",
  "cancel-button": "qts",
  "dropdown-opened": "qtF",
  "dropdown-closed": "qtg",
  "dropdown-menu": "qtD",
  "loading-item": "qtV",
  "placeholder": "qtL",
  "label": "qtT",
  "list-body": "qtz",
  "list-item": "qta",
  "error-state": "qtH body-2",
  "warning": "qtO",
  "warning-icon": "qtm",
  "footer": "qtp",
  "add-icon": "qtx",
  "empty-state": "qtw"
};
