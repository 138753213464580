export default {
  "header": "qST",
  "subtitle": "qSz body-2",
  "date": "qSa",
  "subtitle-error": "qSH",
  "card": "qSO",
  "card-header": "qSm",
  "line-placeholder": "qSp",
  "post-closing-list": "qSx",
  "post-closing-container": "qSw",
  "post-closing-list-element": "qSN",
  "free-trial-disclaimer": "qSv",
  "description": "qSo body-2",
  "mw-100": "qSn",
  "addon-overview": "qSi",
  "addons-container": "qSA"
};
