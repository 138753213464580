export default {
  "transfer-header": "qPh",
  "infobox": "qPf header-section",
  "proof-section": "qPK",
  "proof-link": "qPG",
  "download-icon": "qPr",
  "cancel-button": "qPb",
  "request-refund-button": "qPy",
  "refund-requested-indicator": "qPU",
  "recall-indicator": "qPj",
  "payment-purpose": "qPW",
  "payment-purpose-label": "qPu",
  "repeat-infobox": "qlS qPf header-section",
  "additional-infobox": "qlc qPf header-section",
  "repeat-transfer-action": "qlq",
  "financing-not-compliant-disclaimer": "qlZ"
};
