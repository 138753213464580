export default {
  "sidebar-content": "qXz",
  "section": "qXa",
  "header-wrapper": "qXH",
  "transfer-id": "qXO caption",
  "small-padding": "qXm",
  "title": "qXp",
  "subtitle": "qXx",
  "actions": "qXw"
};
