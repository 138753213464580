export default {
  "wrapper": "qXn",
  "ember-power-select-dropdown": "qXi",
  "status": "qXA body-1",
  "error": "qXY",
  "success": "qXh",
  "type-container": "qXf",
  "type-close": "qXK",
  "date": "qXG",
  "close-icon": "qXr",
  "container": "qXb",
  "container-picto": "qXy",
  "container-picto-status": "qXU",
  "general": "qXj",
  "general-amount": "qXW",
  "unprocessed": "qXu",
  "fx-rate": "qCS",
  "general-counterparty": "qCc body-2",
  "infobox": "qCq",
  "account-infobox": "qCZ qCq",
  "initiator-infobox": "qCR qCq",
  "transaction-infobox": "qCQ qCq",
  "repeat-infobox": "qCe qCq",
  "item": "qCB body-2",
  "eye-icon": "qCE",
  "repeat-transfer-action": "qCd",
  "disclaimer": "qCI",
  "start-verification-link": "qCt",
  "financing-not-compliant-disclaimer": "qCM"
};
