export default {
  "details": "qcg",
  "details-header": "qcD",
  "details-header-amount": "qcV",
  "details-content": "qcL",
  "details-footer": "qcT",
  "invoice-status-container": "qcz body-2",
  "invoice-dates": "qca",
  "overdue": "qcH",
  "details-list": "qcO",
  "details-list-item": "qcm",
  "details-list-item-label": "qcp body-2",
  "details-list-item-value": "qcx",
  "border-top": "qcw",
  "description": "qcN",
  "description-value": "qcv",
  "edit-button": "qco body-2",
  "edit-icon": "qcn",
  "badge-union": "qci",
  "edit-description": "qcA",
  "amount-disclaimer": "qcY",
  "strike-amount": "qch"
};
