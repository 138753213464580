export default {
  "vat-lines": "qgO",
  "vat-line": "qgm",
  "vat-input": "qgp",
  "vat-rate": "qgx",
  "vat-option-button": "qgw",
  "disabled": "qgN",
  "vat-option-tooltip": "qgv",
  "remove-label": "qgo",
  "remove-button": "qgn",
  "vat-total": "qgi",
  "vat-total-amount": "qgA",
  "add-vat-line": "qgY",
  "with-vat-lines": "qgh"
};
