export default {
  "container": "qFF",
  "container-column": "qFg",
  "content-wrapper": "qFD",
  "media-wrapper": "qFV",
  "content": "qFL",
  "title": "qFT",
  "subtitle": "qFz",
  "content-slot": "qFa",
  "sticky-panel": "qFH",
  "sticky-actions": "qFO"
};
