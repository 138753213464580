export default {
  "supplier-selector-wrapper": "qqx",
  "select-supplier": "qqw",
  "dropdown": "qqN",
  "iban": "qqv",
  "card": "qqo body-2",
  "card-content": "qqn",
  "add-btn": "qqi btn btn--square btn--icon-only",
  "archived-card": "qqA",
  "title": "qqY body-1",
  "archived-title": "qqh",
  "edit-supplier-tooltip": "qqf",
  "add-supplier": "qqK btn btn--tertiary",
  "error": "qqG caption",
  "form-title": "qqr title2",
  "dropdown-menu": "qqb",
  "supplier-invoice-detail-menu": "qqy",
  "item-divider": "qqU",
  "menu-item": "qqj body-2 dropdown-overlay",
  "disabled": "qqW",
  "iban-error": "qqu",
  "iban-disclaimer": "qZS"
};
