export default {
  "cell": "qQe",
  "supplier-container": "qQB",
  "overflow": "qQE",
  "cell-label-container": "qQd",
  "cell-label": "qQI",
  "missing": "qQt",
  "flex-label": "qQM",
  "sub-label": "qQP body-2",
  "with-separator": "qQl",
  "file-name-warning": "qQX",
  "self-invoice-icon": "qQC"
};
