export default {
  "cell": "qRK",
  "supplier-container": "qRG",
  "cell-label-container": "qRr",
  "cell-label": "qRb",
  "overflow": "qRy",
  "sub-label": "qRU body-2",
  "overdue": "qRj",
  "file-name-warning": "qRW",
  "missing": "qRu",
  "pending-icon": "qQS",
  "icon": "qQc",
  "flex-label": "qQq",
  "with-separator": "qQZ",
  "self-invoice-icon": "qQR",
  "tooltip-wrapper": "qQQ"
};
