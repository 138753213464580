export default {
  "row": "qlX",
  "item-bulk": "qlC",
  "active": "qlk",
  "disabled": "qlJ",
  "cell": "qls body-2",
  "not-disabled": "qlF",
  "item-amount-disabled": "qlg",
  "item-amount-credit": "qlD",
  "no-padding": "qlV",
  "cell-content": "qlL",
  "centered-cell": "qlT",
  "item-counterparty-icon": "qlz mr-16",
  "avatar": "qla",
  "item-counterparty-name": "qlH",
  "item-counterparty-name-status": "qlO",
  "disputing-chargeback": "qlm",
  "item-counterparty-name-text": "qlp",
  "item-amount": "qlx body-1",
  "item-amount-foreign": "qlw",
  "attachment-icon": "qlN",
  "item-method": "qlv",
  "bank-account-avatar": "qlo",
  "attachment-status-dot": "qln",
  "attached": "qli",
  "missing": "qlA",
  "item-attachment": "qlY",
  "compact": "qlh",
  "upsell-badge": "qlf",
  "fadein-item": "qlK"
};
