export default {
  "filters-checkbox": "qIf mb-8",
  "csv-options-button": "qIK",
  "active": "qIG",
  "options-format-settings-wrapper": "qIr",
  "checkbox": "qIb",
  "options-format-settings": "qIy",
  "hidden": "qIU",
  "visible": "qIj",
  "disabled": "qIW",
  "buttons": "qIu"
};
