export default {
  "label-select": "cuM",
  "puce": "cuP",
  "puce-wrapper": "cul",
  "puce-container": "cuX",
  "readonly": "cuC",
  "label-display": "cuk",
  "label-list-name": "cuJ body-1",
  "label-list-value": "cus body-2"
};
