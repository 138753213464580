export default {
  "insight-pill": "qZJ",
  "insight-pill-figure": "qZs",
  "insight-pill-renderer": "qZF",
  "loading": "qZg",
  "counter": "qZD",
  "error": "qZV",
  "insight-pill-label": "qZL",
  "insight-pill-subtitle": "qZT caption",
  "hidden": "qZz",
  "missing-amounts-warning": "qZa",
  "missing-amounts": "qZH"
};
