export default {
  "header-cell": "qeR",
  "first-col": "qeQ",
  "mid-col": "qee",
  "outstanding-col": "qeB",
  "header-content": "qeE caption-bold",
  "active": "qed",
  "quick-actions": "qeI",
  "empty": "qet"
};
