export default {
  "container": "qtv",
  "item": "qto caption-bold",
  "hover": "qtn",
  "active": "qti",
  "name-wrapper": "qtA",
  "name": "qtY body-2",
  "count": "qth",
  "actions-placeholder": "qtf",
  "actions": "qtK",
  "show": "qtG",
  "action-button": "qtr"
};
