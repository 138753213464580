export default {
  "row": "qBA",
  "active": "qBY",
  "dot": "qBh",
  "red": "qBf",
  "green": "qBK",
  "yellow": "qBG",
  "align-right": "qBr",
  "animated": "qBb",
  "fadein-item": "qBy",
  "cell": "qBU body-2",
  "cell-content": "qBj",
  "amount": "qBW body-1",
  "subtitle": "qBu caption",
  "status": "qES",
  "cell-amount": "qEc"
};
