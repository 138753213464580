export default {
  "container": "qMd",
  "header": "qMI",
  "title": "qMt caption-bold",
  "search-icon": "qMM",
  "text": "qMP body-2",
  "trigger": "qMl",
  "loading-state": "qMX",
  "dropzone-placeholder-wrapper": "qMC",
  "dropzone-placeholder": "qMk",
  "file-loading": "qMJ",
  "search-qonto-cta": "qMs",
  "search-qonto-cta-content": "qMF",
  "tooltip-wrapper": "qMg",
  "invoices-tooltip": "qMD"
};
