export default {
  "modal": "qZb",
  "action-container": "qZy",
  "similar-label": "qZU",
  "info-icon": "qZj",
  "container": "qZW",
  "subtitle": "qZu caption-bold",
  "error": "qRS",
  "item-container": "qRc"
};
