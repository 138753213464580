export default {
  "review": "cUT",
  "review-content": "cUz",
  "review-parameters": "cUa",
  "row": "cUH",
  "row__border": "cUO",
  "label": "cUm",
  "value": "cUp",
  "error": "cUx",
  "tooltip-icon": "cUw",
  "terms-link": "cUN"
};
