export default {
  "team-card": "qEx",
  "card-link": "qEw",
  "shadow": "qEN",
  "history-button": "qEv",
  "cards-button": "qEo",
  "menu-item": "qEn",
  "subtitle": "qEi",
  "delete-team": "qEA"
};
