export default {
  "row": "qel",
  "greyed-out": "qeX",
  "supplier-name": "qeC",
  "cell": "qek",
  "supplier": "qeJ",
  "email-address": "qes",
  "outstanding-balance": "qeF",
  "quick-actions": "qeg",
  "empty": "qeD",
  "supplier-container": "qeV",
  "grey-background": "qeL"
};
