export default {
  "row": "qlG",
  "col": "qlr",
  "header": "qlb",
  "block": "qly",
  "col-5": "qlU",
  "col-6": "qlj",
  "col-9": "qlW",
  "col-12": "qlu",
  "hide": "qXS"
};
