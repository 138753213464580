export default {
  "transaction": "qtQ",
  "transaction-details": "qte",
  "transaction-details-amount": "qtB body-1",
  "credit": "qtE",
  "negative-amount": "qtd",
  "transaction-details-description": "qtI body-2",
  "transaction-remove-cta": "qtt",
  "avatar": "qtM mr-16",
  "spinner": "qtP"
};
